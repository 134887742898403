import React from "react";
import "./hero.css";

interface Props {
  refProp: React.RefObject<HTMLDivElement>;
  setIsCheckoutPopupOpen: (b: boolean) => void;
}

export default function Hero({ refProp, setIsCheckoutPopupOpen }: Props) {
  return (
    <div className="hero" id="about" ref={refProp}>
      <div className="hero1">
        <img
          className="fox"
          src={require("../../assets/XVA Fox 1.png")}
          alt=""
        />

        <div className="section-1">
          <div className="heading-1">$SPLRG UTILITY TOKENS</div>
          <div className="heading-2">
            <p>
              Buy and HODL?
              <br />
              That's so yesterday
            </p>

            <p>
              Buy. HODL. Use.
              <br />
              That's the future
            </p>

            <p>Buy a piece of tomorrow – today</p>
          </div>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">WE ARE SPLURGING A MILLION</div>

        <img src={require("../../assets/fox.png")} alt="" />

        <div className="section">
          Get ready to splurge like never before! We’re turning up the heat with
          our $SPLRG A MILLION Promo – your chance to bag a million $SPLRG
          tokens!
        </div>

        <div className="section">
          <p>Here’s how it works:</p>

          <p className="center">
            <ul>
              <li>
                Spend <span className="w-2">1◎</span> ={" "}
                <span className="w-1">1</span>{" "}
                <span className="w-7">chance</span> to win
              </li>
              <li>
                Spend <span className="w-2">3◎</span> ={" "}
                <span className="w-1">3</span>{" "}
                <span className="w-7">chances</span> to win
              </li>
              <li>
                Spend <span className="w-2">7◎</span> ={" "}
                <span className="w-1">7</span>{" "}
                <span className="w-7">chances</span> to win
              </li>
              <li>
                Spend <span className="w-2">10◎</span> ={" "}
                <span className="w-1">15</span>{" "}
                <span className="w-7">chances</span> to win
              </li>
            </ul>
          </p>

          <br />
          <p>
            The more you splurge, the closer you get to that MILLION $SPLRG
            prize! Imagine a million $SPLRG tokens landing in your wallet just
            in time for the holidays!
            <br />
            So, what are you waiting for? Go ahead. $SPLRG a little
          </p>

          <br />
          <p>
            <b>Promotion ends at 23:59 SGT Wed 11 Dec 2024</b>
          </p>

          <br />
          <p className="right">*Terms and Conditions below</p>

          <br />
          <button onClick={() => setIsCheckoutPopupOpen(true)}>
            BUY $SPLRG NOW
          </button>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">Why $SPLRG</div>
        <div className="section">
          <p>
            Our mission is to flip the script on exclusivity,
            <br />
            jazz up blockchain utility,
            <br />
            and rewrite the playbook for luxury access.
          </p>

          <p>
            By tokenising real world assets into unique, tradable NFTs,
            <br />
            we are reshaping how luxury assets are collected.
            <br />
            Everyone can $SPLRG.
          </p>
        </div>

        <br />

        <div className="heading-big center">What is $SPLRG</div>
        <div className="section">
          <p>$SPLRG = utility tokens + real-world asset NFTS.</p>

          <p>
            $SPLRG will unlock NFTs that are backed by luxury assets, premium
            collectibles, one-of-a-kind experiences, and elite privileges.
          </p>
        </div>

        <div className="splrg-images">
          <img src={require("../../assets/splrg-tokens.png")} alt="" />
          <img src={require("../../assets/splrg-rwa-nfts.png")} alt="" />
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">What you get when you $SPLRG</div>
        <div className="section">
          The $SPLRG market offering will initially be divided into 3 categories
          assets, collectibles & experiences. Our mission is to only provide
          highly premium, limited edition, and customized Real World Assets for
          the $SPLRG customer.
        </div>
        <br />

        <div className="heading center">Luxury Assets.</div>
        <img src={require("../../assets/luxary-assets.png")} alt="" />
        <div className="section">
          With over $1.5 million worth of premium wines in our warehouse, we are
          now expanding our partnerships to include other luxury assets. To
          qualify for tokenization, these assets must have a well-documented
          price history and demonstrate yield potential. This approach extends
          beyond wines to include premium spirits, luxury bags, and high-end
          watches.
        </div>
        <br />

        <div className="heading center">Collectibles.</div>
        <img src={require("../../assets/collectables.png")} alt="" />
        <div className="section">
          We’re collaborating with renowned brands to craft exclusive, highly
          sought-after collectibles, while also developing our own line of
          $SPLRG-branded products. Collaboration is at the heart of what we do,
          spanning both the fashion industry and the broader Web3 space. By
          teaming up with luxury brands and key players in the Web3 community,
          we combine expertise and influence to create standout, one-of-a-kind
          products. These partnerships not only enhance the $SPLRG brand but
          also unlock new opportunities for growth and visibility.
        </div>
        <br />

        <div className="heading center">Experiences.</div>
        <img src={require("../../assets/experiences.png")} alt="" />
        <div className="section">
          We’re actively in talks with event companies, exclusive membership
          communities, and luxury hotel groups to curate unique, high-end
          experiences exclusively for the $SPLRG ecosystem. Our mission is to
          amplify the value of $SPLRG by offering extraordinary, life-enhancing
          opportunities that enrich and elevate the lives of our community
          members.
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">Key $SPLRG Announcements</div>
        <div className="section">
          Come 12 Dec 2024, $SPLRG will be listed on the XT.com exchange. This
          is the start of a few listings.
          <br />
          You can also look forward to our NFTs being sold in our marketplace.
          <br />
          <br />
          So a few things to look forward to this festive season.
          <br />
          Soon, you'll be trading these gems across crypto exchanges worldwide.
          Remember, in this Web3 world we call home,{" "}
          <b>luxury is the new currency!</b>
        </div>
      </div>

      <br />
      <br />
      <br />

      <div className="hero2">
        <div className="heading-big center">$SPLRG Tokenomics</div>
        <div className="section">
          <p>
            Our market capitalisation is 500 million $SPLRG tokens, with an
            initial release of 100 million tokens. The planned Q4 2024 listing
            on the XT Exchange is set to significantly boost the token's
            visibility and liquidity.
          </p>

          <p>
            $SPLRG, a utility token built on the Solana ecosystem, offers
            exclusive access to our NFTs and additional privileges for holders.
            As part of the pre-listing phase, we are offering 100 million $SPLRG
            tokens at an attractive price of USD0.08 per token.
          </p>

          <p>
            Tokens are available for purchase with a minimum commitment of 1 SOL
            and a maximum of 10 SOL, subject to a vesting period.
          </p>
        </div>

        <br />

        <img
          className="big-image"
          style={{ backgroundColor: "white", maxWidth: "65%" }}
          src={require("../../assets/tokenomics.png")}
          alt=""
        />

        <br />

        <div className="section">
          <p>
            For a quick summary of what $SPLRG is all about{" "}
            <a
              href="https://xva.asia/info1.pdf"
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>
            <br />
            For a more detailed White Paper on $SPLRG{" "}
            <a
              href="https://xva.asia/info2.pdf"
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>
            <br />
            To check out our FAQ’s on $SPLRG{" "}
            <a href="/FAQs.pdf" target="_blank" rel="noreferrer">
              click here
            </a>
          </p>
        </div>

        <br />

        <div className="section">
          <p>*Terms and Conditions for $SPLRG A MILLION Promo:</p>
          <p>
            <ol>
              <li>
                <b>Single Winner</b>: Only one lucky participant will be
                selected as the winner of the $SPLRG A MILLION promotion.
              </li>
              <li>
                <b>Fair Selection Process</b>: The winner will be chosen through
                a random, transparent, and verifiable draw conducted using a
                secure automated program. The entire process will be publicly
                visible to ensure fairness and build trust.
              </li>
              <li>
                <b>Assignment of Numbers</b>: All promo numbers and their
                corresponding wallet addresses will be published on our website
                and social media channels for transparency.
              </li>
              <li>
                <b>Token Distribution</b>: The winner will receive a total of 1
                million $SPLRG tokens, airdropped directly to their wallet.
                Distribution will occur in instalments of 5% per month until the
                full amount is paid.
              </li>
              <li>
                <b>Non-Transferable Wallet</b>: The winning wallet address
                cannot be transferred or changed under any circumstances.
              </li>
            </ol>
          </p>
          <p>By participating, entrants agree to these terms and conditions.</p>
        </div>
      </div>
    </div>
  );
}
