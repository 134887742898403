import React, { useEffect, useState } from "react";
import styles from "./CheckoutModal.module.css";
import { IoMdClose } from "react-icons/io";

import {
  TREASURY_ADDRESS,
  XVA_LOT_PRICE_USD,
  XVA_LOT_SIZE,
} from "../../utils/web3config";

import { toast } from "react-toastify";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import ReactLoading from "react-loading";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

interface PopupModalProps {
  isOpen: boolean;
  onClose: () => void;
  setQuantity: (num: string) => void;
  email: string | null;
  walletAddress: string | undefined;
  tokenPrice: number;
  quantity: string;
  total: number;
  setTotal: (num: number) => void;
}

const PROMO_CODE_MAP: Record<string, Record<string, number>> = {
  // BASCSPLRG: {
  // "1": 10,
  // "3": 15,
  // "5": 25,
  // "10": 25,
  // },
};

const CheckoutModal = ({
  isOpen,
  onClose,
  email,
  total,
  walletAddress,
  tokenPrice,
  quantity,
  setTotal,
  setQuantity,
}: PopupModalProps) => {
  const signer = useWallet();
  const address = signer.publicKey;
  const { connection } = useConnection();

  const [currency, setCurrency] = useState("SOL");
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [promoCode, setPromoCode] = useState("");

  // promo code handle change
  const handlePromoCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
  };

  if (!isOpen) return null;

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    console.log(value);
    const numericInput = value.replace(/[^0-9.]/g, ""); // Allow digits and decimal point
    let floatValue = parseFloat(numericInput);

    // Do not immediately apply minimum limit if the user is typing a valid decimal value

    console.log(floatValue);

    if (floatValue > 20) {
      value = "20"; // Apply maximum limit
    }

    setQuantity(value);
    const totalCost = XVA_LOT_PRICE_USD * floatValue;
    setTotal(parseFloat(totalCost.toFixed(2)));
  };

  const handleClose = () => {
    setIsLoading1(false);
    setIsLoading2(false);
    onClose();
  };

  const buyTokenV3 = async () => {
    let transactionId;
    let quantity_value = parseFloat(quantity);

    // if not "" or "CTAMA" then invalid promo code

    if (promoCode != "" && !PROMO_CODE_MAP[promoCode]?.[quantity]) {
      toast.error("Invalid Promo Code");
      return;
    }

    if (!signer) {
      toast.error("No crypto wallet detected");
      setIsLoading2(false);
      return;
    }

    if (!address || !signer.signTransaction) {
      toast.error("No wallet address detected");
      setIsLoading2(false);
      return;
    }

    const balance = await connection.getBalance(address);
    const lamportsToSend = Math.floor(quantity_value * web3.LAMPORTS_PER_SOL);
    if (balance < lamportsToSend + 1_000_000) {
      toast.error("Insufficent balance");
      setIsLoading2(false);
      return;
    }

    setIsLoading2(true);
    let signature: string | undefined = undefined;
    for (let i = 0; i < 3; i++)
      try {
        let swapTx = await fetch(
          "https://getbuytransaction-rp5h7zeemq-uc.a.run.app",
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              buyerAddress: signer.publicKey,
              solAmount: parseInt(quantity),
              promoCode,
            }),
          },
        ).then((r) => r.json());

        let transaction = web3.Transaction.from(
          Buffer.from(swapTx.transaction, "base64"),
        );
        let signedTransaction = await signer.signTransaction(transaction);

        signature = await connection.sendRawTransaction(
          signedTransaction.serialize(),
          {
            maxRetries: 5,
            preflightCommitment: "processed",
          },
        );
        console.log("sent transaction", signature);

        await connection.confirmTransaction(
          {
            signature,
            ...swapTx.blockhashInfo,
          },
          "processed",
        );

        toast.success("Transaction sent successfully");

        break;
      } catch (e: any) {
        console.error(`attempt ${i + 1} failed: ${e?.message ?? e}`);
        console.error(e);
        toast.error(e?.message || String(e));
        setIsLoading2(false);
        if (e?.message == "User rejected the request.") break;
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

    //https://registerbsctransaction-rp5h7zeemq-uc.a.run.app

    // let requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ transactionId: transactionId }),
    // };

    // try {
    //   const response = await fetch(
    //     process.env.REACT_APP_PRODUCTION_FIREBASE_FUNCTIONS_URL_REGISTER_BSC ||
    //       "http://127.0.0.1:5001/xva-collective/us-central1/registerBscTransaction",
    //     requestOptions
    //   );
    //   let promise = response.json();
    //   toast.promise(promise, {
    //     pending: "Recording transaction on database, please wait...",
    //     success: "Transaction recorded successfully",
    //     error: "Transaction recording failed, please contact us for support",
    //   });
    //   const data = await promise;
    //   if (data.success) {
    //     console.log("success verification on firebase");
    //     toast.success("Transaction Successful");
    //   } else {
    //     console.error("Unsuccessful verification of tx on firebase");
    //     toast.error(
    //       "Transaction unsuccessful, please contact support before making another payment"
    //     );
    //   }
    // } catch (error: any) {
    //   // Getting the Error details.
    //   const message = error.message;
    //   console.error(error);
    //   toast.error(message);
    // }
    setIsLoading2(false);
    // onClose();
  };

  return (
    <div className={styles.checkoutModal}>
      <div className={styles.checkoutModalContent}>
        <IoMdClose className={styles.closeIcon} onClick={handleClose} />
        <h2>Confirm Your Details</h2>
        <p>Please double-check your details before proceeding:</p>
        <br />
        <ul>
          <li>
            <strong>Crypto Wallet Address:</strong>{" "}
            {address?.toString() || "Not connected"}{" "}
            <span>(Tokens will be sent here, so triple-check this!)</span>
          </li>
          <br />
          <li>
            <strong>SOL:</strong>{" "}
            <div className={styles.quantityInput}>
              <label>
                <input
                  type="radio"
                  name="quantity"
                  value="1"
                  onChange={handleQuantityChange}
                  defaultChecked={true}
                />
                ◎1
              </label>

              <label>
                <input
                  type="radio"
                  name="quantity"
                  value="3"
                  onChange={handleQuantityChange}
                />
                ◎3
              </label>

              <label>
                <input
                  type="radio"
                  name="quantity"
                  value="5"
                  onChange={handleQuantityChange}
                />
                ◎5
              </label>

              <label>
                <input
                  type="radio"
                  name="quantity"
                  value="10"
                  onChange={handleQuantityChange}
                />
                ◎10
              </label>
            </div>
          </li>
          {/* <li>
            <strong>Total Price:</strong> USD ${total.toFixed(2)} (USD $
            {XVA_LOT_PRICE_USD} per lot)
          </li> */}
          {/* <li>
            <strong>Total Price:</strong> {total.toFixed(2)} ($ {tokenPrice} per
            lot)
          </li> */}
        </ul>

        {/* <div className={styles.promo}>
          <div>Promo Code</div>
          <input
            type="text"
            value={promoCode}
            onChange={handlePromoCodeChange}
            className="promoInput"
          />
        </div> */}

        {getPromoCodeExtraPercent(promoCode, quantity)}
        <br />

        <div className={styles.walletbutton}>
          <WalletMultiButton />
        </div>

        {(isLoading2 && (
          <ReactLoading type={"bars"} className={styles.loading} color="#fff" />
        )) || (
          <button
            className={styles.primaryButton}
            onClick={buyTokenV3}
            disabled={isLoading1 || isLoading2}
          >
            buy
          </button>
        )}
      </div>
    </div>
  );
};

export default CheckoutModal;

function getPromoCodeExtraPercent(promoCode: string, quantity: string) {
  if (promoCode == "") return <div>&nbsp;</div>;
  let extraPercent = PROMO_CODE_MAP[promoCode]?.[quantity];
  if (extraPercent)
    return (
      <div
        style={{ color: "#4caf50", fontWeight: "bold", textAlign: "center" }}
      >
        Extra: +{extraPercent}% tokens for ◎{quantity}
      </div>
    );
  else
    return (
      <div
        style={{ color: "#e74c3c", fontWeight: "bold", textAlign: "center" }}
      >
        Invalid promo code
      </div>
    );
}
